import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "../App";
import PageNotFound from "../utils/PageNotFound";
import Home from "../pages/Home";
import Team from "../pages/Team";
import FAQ from "../pages/FAQ";
import About from "../pages/About";
import Policy from "../pages/Policy";
import Terms from "../pages/Terms";
import Careers from "../pages/Careers";
import Projects from "../pages/Projects"
import Services from "../pages/Services"
import TeamInquiry from "../pages/TeamInquiry"
import Pongal from "../pages/Pongal"


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Home />} />
                <Route path="/team" element={<Team />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/services" element={<Services />} />
                <Route path="/team-inquiry" element={<TeamInquiry />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/privacy-policy" element={<Policy />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/events" element={<Pongal />} />
            </Route>
            <Route path="/*" element={<PageNotFound />} />
        </Routes>
    );
}