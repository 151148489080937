import React, { Fragment } from "react";
import "./About.css";
import { Helmet } from "react-helmet-async";

export default function Careers() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Careers</title>
        <meta name="title" content="Keture | About"></meta>
        <meta
          name="description"
          content="Join Our 2025 Remote Internship Program in Software Development!"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Join Our 2025 Remote Internship Program in Software Development!"
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Join Our 2025 Remote Internship Program in Software Development!"
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed px-4 lg:px-8 bg-img py-20">
        <div className="space-y-3 max-w-4xl mx-auto">
          <h1 className="text-5xl text-gray-200 font-black">
            Join Our 2025 Remote Internship Program in Software Development!
          </h1>
          <p className="text-xl">
            We are a growing software service provider based in Sri Lanka,
            excited to announce our {" "}
            <strong>6-month fully remote, unpaid internship program</strong> for
            2025. This is a unique opportunity for aspiring software developers
            to gain hands-on experience across the entire software development
            lifecycle, from <strong>design</strong> and{" "}
            <strong>development</strong>
            to <strong>testing</strong>, <strong>deployment</strong>, and{" "}
            <strong>DevOps</strong>.
          </p>
          <h2 className="text-xl font-bold pt-10">What You'll Gain:</h2>
          <ul className="text-lg">
            <li>Exposure to full-stack application development.</li>
            <li>
              Practical experience with cutting-edge technologies:{" "}
              <strong>Fastify</strong>, <strong>React.js</strong>, and modern
              DevOps practices.
            </li>
            <li>
              Mentorship and guidance in <strong>frontend</strong>,{" "}
              <strong>backend</strong>, and{" "}
              <strong>full-stack development</strong>.
            </li>
          </ul>
          <h2 className="text-xl font-bold pt-10">Why Choose Us?</h2>
          <ul className="text-lg">
            <li>
              <strong>Comprehensive Learning:</strong> Engage in real-world
              projects covering all aspects of software development.
            </li>
            <li>
              <strong>Remote Flexibility:</strong> Work from anywhere while
              collaborating with a passionate and skilled team.
            </li>
            <li>
              <strong>6-Month Duration:</strong> Build expertise over an
              extended period while working on substantial projects.
            </li>
          </ul>
          <h2 className="text-xl font-bold pt-10">Who Can Apply?</h2>
          <p className="text-lg">
            We welcome{" "}
            <strong>students, recent graduates, and tech enthusiasts</strong>{" "}
            looking to kickstart their careers in software development.
          </p>
          <h2 className="text-xl font-bold pt-10">To Apply:</h2>
          <p className="text-xl">
            Send your resume and a brief introduction to{" "}
            <a
              href="mailto:careers@keture.com"
              className="text-orange-400"
            >
              careers@keture.com
            </a>
            .
          </p>
        </div>
      </section>
    </Fragment>
  );
}
