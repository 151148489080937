import React, { Fragment } from "react";
import "./pongal.css";
import { Helmet } from "react-helmet-async";

export default function NewYear() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Happy Pongal 2025</title>
        <meta name="title" content="Keture | About" />
        <meta
          name="description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed mx-auto px-4 lg:px-8 py-10 sm:py-20 min-h-screen flex flex-col justify-center align-middle">
        <div className="space-y-3 text-center">
          <div className="sunrise">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="sun"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1417.3 1417.3"
            >
              <g id="sun_wheel">
                <path
                  id="XMLID_29_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M708.7,551.5c2.1,0,4.2,0.1,6.2,0.1l35-880.9
		c-13.7-0.5-27.5-0.8-41.3-0.8c-182.4,0-353.8,47-502.7,129.6l426.7,771.6C655.1,558.6,681.1,551.5,708.7,551.5z"
                />
                <path
                  id="XMLID_30_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M761.7,560.6l297.4-830.1
		c-97.2-34.8-201-55.6-309.1-59.8l-35,880.9C731.3,552.2,747,555.4,761.7,560.6z"
                />
                <path
                  id="XMLID_32_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M596.3,598.7L-33.9-17.7
		c-30.2,30.9-58.5,63.6-84.6,98l702,533.3C587.5,608.4,591.7,603.4,596.3,598.7z"
                />
                <path
                  id="XMLID_33_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M632.6,571.1L205.9-200.5
		C117.2-151.4,36.5-89.6-33.9-17.7l630.2,616.4C606.9,587.9,619.2,578.5,632.6,571.1z"
                />
                <path
                  id="XMLID_34_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M826,604l657.9-586.8
		c-69.4-77.7-150.3-145-240-199L789.6,573.9C803.2,582.1,815.5,592.3,826,604z"
                />
                <path
                  id="XMLID_35_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M865.8,704.5l881.2-23.2
		c-2.6-100.2-19.4-196.8-48.4-288L858.5,660.9C862.9,674.7,865.4,689.4,865.8,704.5z"
                />
                <path
                  id="XMLID_36_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M583.5,613.6l-702-533.3
		C-249.3,252.2-327.7,466-330,698l881.5,9C551.8,671.9,563.7,639.6,583.5,613.6z"
                />
                <path
                  id="XMLID_37_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M838,619.3l725.3-501.2
		c-24.4-35.3-51-68.9-79.5-100.9L826,604C830.3,608.9,834.3,614,838,619.3z"
                />
                <path
                  id="XMLID_38_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M865.9,708.7c0,7.4-0.5,14.6-1.5,21.7l873.2,121.5
		c6.5-46.8,9.8-94.6,9.8-143.2c0-9.2-0.1-18.3-0.4-27.4l-881.2,23.2C865.8,705.9,865.9,707.3,865.9,708.7z"
                />
                <path
                  id="XMLID_39_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M852.5,645.2l806.7-356.1
		c-26.6-60.2-58.8-117.4-95.9-171L838,619.3C843.6,627.4,848.5,636.1,852.5,645.2z"
                />
                <path
                  id="XMLID_40_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M858.5,660.9l840.2-267.6
		c-11.3-35.6-24.5-70.4-39.5-104.2L852.5,645.2C854.8,650.3,856.8,655.6,858.5,660.9z"
                />
                <path
                  id="XMLID_4_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1174.2-220.2c-37.1-18.7-75.6-35.1-115.1-49.3
		L761.7,560.6c6,2.1,11.8,4.6,17.4,7.5c3.6,1.8,7.1,3.7,10.5,5.8l454.2-755.7C1221.2-195.4,1197.9-208.2,1174.2-220.2z"
                />
                <path
                  id="XMLID_42_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M746.8,861.2l213.8,855.4
		c127.2-31.7,245.1-86.8,348.9-160.5L799.6,836.9C783.9,848.1,766,856.4,746.8,861.2z"
                />
                <path
                  id="XMLID_43_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M708.7,865.9v881.5c32.2,0,64.1-1.5,95.6-4.4
		l-81.2-877.9C718.4,865.6,713.5,865.9,708.7,865.9z"
                />
                <path
                  id="XMLID_44_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M723.1,865.2l81.2,877.9
		c53.4-4.9,105.6-13.8,156.3-26.4L746.8,861.2C739.1,863.1,731.2,864.4,723.1,865.2z"
                />
                <path
                  id="XMLID_45_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M799.6,836.9l509.9,719.2
		c21.9-15.6,43.2-32,63.8-49.1L809.2,829.5C806.1,832.1,802.9,834.5,799.6,836.9z"
                />
                <path
                  id="XMLID_46_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M864.4,730.3c-3.8,27.3-14.6,52.4-30.5,73.4
		l702.1,533.1c105.4-138.5,176.7-304.4,201.6-485L864.4,730.3z"
                />
                <path
                  id="XMLID_47_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M809.2,829.5l564.1,677.5
		c60.4-50.4,115-107.5,162.7-170.1L833.9,803.7C826.6,813.2,818.4,821.8,809.2,829.5z"
                />
                <path
                  id="XMLID_48_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M608,829.4L43.5,1506.5
		c42,35.1,86.8,66.9,134.1,95l450.7-757.8C621.1,839.5,614.4,834.7,608,829.4z"
                />
                <path
                  id="XMLID_49_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M599.5,821.7L-13,1455.8
		c18.2,17.6,37,34.5,56.4,50.7L608,829.4C605.1,826.9,602.2,824.4,599.5,821.7z"
                />
                <path
                  id="XMLID_50_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M560.4,761l-831.5,293.5
		C-216.9,1208-127.6,1345-13,1455.8l612.4-634.1C582.1,805,568.6,784.2,560.4,761z"
                />
                <path
                  id="XMLID_51_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M553,730.4l-873.2,122.1
		c9.7,69.8,26.3,137.3,49.1,201.9L560.4,761C557,751.2,554.4,741,553,730.4z"
                />
                <path
                  id="XMLID_52_"
                  fill="#FAD45A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M628.3,843.8l-450.7,757.8
		c155.4,92.6,337,145.9,531.1,145.9V865.9C679.3,865.9,651.8,857.8,628.3,843.8z"
                />
                <path
                  id="XMLID_53_"
                  fill="#F4566F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M551.5,708.7c0-0.5,0-1.1,0-1.6l-881.5-9
		c0,3.5-0.1,7.1-0.1,10.6c0,48.8,3.4,96.9,9.9,143.9L553,730.4C552,723.3,551.5,716.1,551.5,708.7z"
                />
              </g>
              <g id="sun_1_">
                <circle
                  id="XMLID_1_"
                  fill="#F0715C"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  cx="708.7"
                  cy="708.7"
                  r="157.2"
                />
                <path
                  id="XMLID_2_"
                  fill="#FFFFFF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M560.4,678.6c10.2-59.3,51.5-93.7,90.5-111.1
		c41-18.3,55.1-3.4,27.6,24.6c-36.4,37.1-40.1,51.5-58.9,97.6C602.4,731.7,551.7,729.5,560.4,678.6z"
                />
              </g>
              <g id="mountains">
                <path
                  id="XMLID_7_"
                  fill="#596A91"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M684.9,913.8c0.2,0.2,0.4,0.4,0.6,0.5
		c0.8,0.4,1.6,0.7,2.4,1.1c24.5,11.1,49,22.3,73.5,33.4c8.6-5.7,16.3-14.3,22.7-22.4c15.6-19.5,31.2-39,46.7-58.5
		c-22.3-11.7-44-24.6-65-38.6c-10.3-6.9-20.4-14-30.4-21.4c-4.4-3.3-8.8-6.6-14-8.2c-4.3-1.3-8.9-1.2-13.3-0.8
		c-33.9,3.5-63.2,29.7-93.9,43.3C633.6,869.3,659.5,891.8,684.9,913.8z"
                />
                <path
                  id="XMLID_149_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M55.1,815.4c-7.5-6.3-14.8-12.8-22-19.1
		C22.7,787,11.7,777.8,0,769.8v89.9c16.8-13.5,33.6-27,50.4-40.5C52,817.9,53.5,816.6,55.1,815.4z"
                />
                <path
                  id="XMLID_19_"
                  fill="#616B84"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M33.5,596.5c-0.9-2.4-2.6-4.4-4.2-6.5
		c-7-9.5-10.1-21.2-14.7-32.1c-3.3-7.9-8.1-15.8-14.7-21v94.3c11.3-6.2,23.4-11.3,35.6-14.2c-0.6-4.5-0.9-9-1.1-13.4
		C34.4,601.2,34.3,598.7,33.5,596.5z"
                />
                <path
                  id="XMLID_22_"
                  fill="#8B94A5"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M85.7,622.2c-0.2-2.5-0.5-5-0.9-7.5
		c-5-31.6-29.1-57-55.7-74.9C19.7,533.6,9.9,528,0,522.5v14.3c6.6,5.2,11.3,13.1,14.7,21c4.6,10.9,7.7,22.6,14.7,32.1
		c1.5,2.1,3.3,4.1,4.2,6.5c0.8,2.2,0.9,4.7,0.9,7.1c0.2,4.5,0.5,9,1.1,13.4C52.9,612.9,70.3,613.5,85.7,622.2z"
                />
                <path
                  id="XMLID_15_"
                  fill="#596D3A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M267,848c-11.4-9.9-23.9-20.2-27.9-34.7
		c-5.1-18.4,4.9-38.3,0.9-57c-2.1-9.5-7.6-17.8-11.2-26.9c-9-22.6-6.6-50.5-23-68.5c-6.1-6.7-14.2-11.1-22.8-14.3
		c-22.4-0.4-45.9,7.2-66.1-2.3c-11-5.2-19.3-14.9-29.7-21.3c-0.5-0.3-1-0.6-1.6-0.9c-26.1-14.8-58.1-6.1-85.7,9v138.6
		c11.7,8,22.7,17.3,33.1,26.5c7.2,6.4,14.5,12.9,22,19.1c13.4-10.6,27.5-20.9,44-25c26.9-6.7,55.2,4.1,78.7,18.8
		s44.6,33.4,69.7,45.2c12,5.6,24.6,9.5,37.5,12.8C280.2,859.9,273.5,853.6,267,848z"
                />
                <path
                  id="XMLID_18_"
                  fill="#839662"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M351.4,829.4c-15.5-11.4-31.5-21.9-48.1-31.5
		c-10.2-21.8-20.4-43.6-30.5-65.4c-0.3-0.7-0.7-1.5-1-2.2c-16.8-36-39.4-76.5-78.5-82.7c-3.4-0.5-6.8-0.8-10.2-0.9
		c8.6,3.2,16.7,7.5,22.8,14.3c16.4,18,13.9,45.9,23,68.5c3.6,9,9.1,17.4,11.2,26.9c4,18.7-6,38.6-0.9,57c4,14.5,16.5,24.8,27.9,34.7
		c6.5,5.7,13.2,12,17.9,19.2c1,0.3,2,0.5,3.1,0.8C307.8,853,329.1,840.1,351.4,829.4z"
                />
                <path
                  id="XMLID_17_"
                  fill="#566384"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M342.7,729.9c-10.7-11.2-16.6-26-25.2-38.8
		c-0.5,0.1-0.9,0.2-1.4,0.3c-5.5,1.2-10.1,4.8-14.3,8.4c-11.1,9.6-20.9,20.7-29.1,32.8c10.2,21.8,20.4,43.6,30.5,65.4
		c16.6,9.6,32.7,20.1,48.1,31.5c2.4-1.1,4.8-2.3,7.2-3.4c11.7-5.3,24.2-10.1,37-9.3c7.2,0.5,13.9,2.7,20.3,5.9
		c9.4-8.2,18.8-16.3,28.2-24.5c-3.1-5.9-6.7-11.5-11.2-16.4C409.3,755.9,366.9,755.2,342.7,729.9z"
                />
                <path
                  id="XMLID_20_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M480.5,767.4c-13.1-6.9-26.7-12.8-40.7-17.6
		c-29.6-10.1-62.2-15.9-85.4-36.8c-11-9.9-22.5-24.1-36.8-22c8.6,12.9,14.5,27.7,25.2,38.8c24.2,25.3,66.5,26,90.1,51.9
		c4.5,4.9,8.1,10.5,11.2,16.4c5.3-4.6,10.5-9.1,15.8-13.7C466.6,778.6,473.4,772.7,480.5,767.4z"
                />
                <path
                  id="XMLID_26_"
                  fill="#8B94A5"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M684.9,913.8c-25.4-21.9-51.3-44.5-70.7-71.5
		c-5.2-7.3-10-15-14.2-23c-12.8-25-21.7-55.8-47.3-67.4c-8.9-4.1-18.8-5.1-28.6-3.9c8.8,0.7,17.3,5,23.8,11.1
		c8.7,8.2,14.1,19.3,17.6,30.7c9,28.9,7.5,60.7,20.1,88.2c9.3,20.3,26.3,37,46.6,46.1c12.6-8.1,26.8-16.4,41.5-13.7
		c4.1,0.7,7.9,2.3,11.7,4C685.3,914.1,685.1,913.9,684.9,913.8z"
                />
                <path
                  id="XMLID_31_"
                  fill="#616B84"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M585.7,877.9c-12.6-27.5-11-59.4-20.1-88.2
		c-3.6-11.4-9-22.5-17.6-30.7c-6.5-6.1-15-10.3-23.8-11.1c-7.6,0.9-15,3.2-22,6.3c-7.7,3.5-14.9,8.1-21.7,13.2
		c-7.1,5.3-13.9,11.2-20.6,17c-14.7,12.7-29.3,25.5-44,38.2c11.8,6.1,22.2,16,31.8,25.5c25.1,25.1,50,50.5,74.6,76.1
		c14-0.3,28,0.3,41.8,2.7c15.1,2.6,29.5,7.2,43.6,12.9c8.1-5.2,16.2-10.4,24.3-15.6c0.1-0.1,0.3-0.2,0.4-0.2
		C611.9,914.9,594.9,898.2,585.7,877.9z"
                />
                <path
                  id="XMLID_24_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M522.2,924.3c-24.6-25.6-49.5-51-74.6-76.1
		c-9.6-9.5-19.9-19.4-31.8-25.5c-6.3-3.3-13-5.5-20.3-5.9c-2-0.1-4-0.1-6,0c11.2,4.5,21.1,12.2,28.1,22c8.7,12.1,12.9,27.1,21.9,39
		c7,9.3,16.7,16.4,23.9,25.6c5.6,7.2,9.6,15.4,13,23.9c0.9-0.1,1.8-0.2,2.7-0.2C493.5,925.8,507.8,924.6,522.2,924.3z"
                />
                <path
                  id="XMLID_27_"
                  fill="#597A59"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M439.6,877.8c-9-11.9-13.2-26.9-21.9-39
		c-7-9.8-16.9-17.5-28.1-22c-10.7,0.7-21.1,4.8-31,9.3c-2.4,1.1-4.8,2.2-7.2,3.4c-22.4,10.7-43.7,23.7-63.5,38.5
		c26.2,6.3,53.3,10.2,78.4,20.2c24.9,9.9,46.4,25.3,66.7,42.8c14.5-1.2,29-2.5,43.5-3.7c-3.4-8.5-7.4-16.7-13-23.9
		C456.3,894.2,446.6,887.1,439.6,877.8z"
                />
                <path
                  id="XMLID_21_"
                  fill="#516289"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M460.6,998.7c-11.7-8.2-25.3-13.2-38.9-17.8
		c-8.6-2.9-17.4-5.6-25-10.4c-21.1-13.4-30.1-40.2-49.9-55.4c-23.5-17.9-56.3-15.5-84.5-24.4c-12.7-4-24.5-10.2-35.8-17.2
		c-24.2-14.9-46.3-33.1-65.5-54c-8.4-9.1-16.5-19-27.7-24.3c-11.1-5.2-23.7-5.3-36.1-4.4c-15.6,4.4-29.1,14.2-41.9,24.4
		c-1.6,1.2-3.1,2.5-4.7,3.7c-16.8,13.5-33.6,27-50.4,40.5v109.3c11.9-3,23.8-5.9,35.8-8.9c15-3.7,30.5-7.5,45.9-5.3
		c17.9,2.6,33.5,13.1,48.7,22.9c26.5,17.1,53.7,33.1,81.5,47.8c34.7-11.2,70.8-18.2,107.2-20.6c21.8-1.4,43.8-1.2,65.1,3.9
		c39.9,9.4,72.9,34.5,106.3,59.1c5.5-1.6,10.8-3.5,16.1-5.6C495.3,1037.9,482.3,1013.9,460.6,998.7z"
                />
                <path
                  id="XMLID_25_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M557.2,1033.7c-47.8-24.4-83.5-67.8-124.2-102.8
		c-20.3-17.5-41.8-32.9-66.7-42.8c-25-10-52.1-13.8-78.4-20.2c-13.9-3.4-27.6-7.4-40.5-13.5c-25.1-11.8-46.2-30.5-69.7-45.2
		s-51.8-25.5-78.7-18.8c-0.7,0.2-1.4,0.4-2,0.6c12.4-0.9,25-0.9,36.1,4.4c11.2,5.3,19.3,15.2,27.7,24.3c19.2,20.9,41.3,39.1,65.5,54
		c11.3,7,23.1,13.2,35.8,17.2c28.2,8.8,61,6.4,84.5,24.4c19.9,15.1,28.8,42,49.9,55.4c7.7,4.9,16.4,7.6,25,10.4
		c13.6,4.5,27.2,9.6,38.9,17.8c21.7,15.1,34.8,39.2,46,63.5c17.6-6.9,34.3-16.2,49.4-27.6C556.3,1034.4,556.8,1034,557.2,1033.7z"
                />
                <path
                  id="XMLID_63_"
                  fill="#719671"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M130.3,977.6c-15.2-9.8-30.8-20.3-48.7-22.9
		c-4.7-0.7-9.5-0.8-14.2-0.5c10.2,2.9,19.4,10.6,26,19.3c9.2,12.3,15,26.9,24.2,39.3c5.7,7.7,12.7,14.4,19.6,21
		c5.3,5.1,10.7,10.2,16,15.3c19-9.2,38.6-17.2,58.7-23.7C184,1010.7,156.8,994.7,130.3,977.6z"
                />
                <path
                  id="XMLID_66_"
                  fill="#597A59"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M117.6,1012.8c-9.2-12.3-14.9-27-24.2-39.3
		c-6.5-8.7-15.8-16.4-26-19.3c-10.6,0.6-21.2,3.2-31.6,5.8c-11.9,3-23.8,5.9-35.8,8.9V1157c27-17.5,51-40.5,76.6-60.4
		c23.8-18.4,49.5-34.3,76.5-47.5c-5.3-5.1-10.7-10.2-16-15.3C130.2,1027.2,123.3,1020.5,117.6,1012.8z"
                />
                <path
                  id="XMLID_61_"
                  fill="#5C647A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M518,924.4c-13,0.4-25.9,1.5-38.8,2.6
		c-15.4,1.3-30.8,2.6-46.2,3.9c40.7,35.1,76.4,78.5,124.2,102.8c20.5-15.6,39.1-35.3,63.8-42.3c7.3-2.1,14.5-2.9,21.8-2.9
		c-6.1-4.8-12.8-9.1-19.3-13.2C590.4,954.5,555.7,933.1,518,924.4z"
                />
                <path
                  id="XMLID_64_"
                  fill="#6F7D9B"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M710.2,1008.3c5.2-4.1,10.4-8.2,15.6-12.3
		c-1-0.3-1.9-0.7-2.9-1c-39.9-14.8-76.2-39.3-115.3-55.1c-14.1-5.7-28.5-10.3-43.6-12.9c-13.8-2.4-27.8-3-41.8-2.7
		c-1.4,0-2.8,0.1-4.2,0.1c37.7,8.7,72.4,30.1,105.6,51c6.5,4.1,13.1,8.4,19.3,13.2C665.5,988.7,688.1,998,710.2,1008.3z"
                />
                <path
                  id="XMLID_77_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M831.6,804.2c7.6-10.1,12-22.3,18.4-33.3
		c5.4-9.2,12.9-18,22.5-21.9c-1.6,0.1-3.2,0.3-4.8,0.6c-15.9,2.7-28.9,14-40.9,24.8c-20.3,18.3-40.7,36.6-61,54.9
		c8.2,5.5,16.6,10.8,25,16C801.4,830,820.1,819.5,831.6,804.2z"
                />
                <path
                  id="XMLID_79_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M936.2,759.3c2.1-0.8,4.1-2,5.4-3.8
		c1.2-1.6,1.8-3.5,2.2-5.5c1.9-8.6,1.1-17.7,4-26c2.5-7.1,7.6-13.2,14.2-16.7c4.8-2.6,10.9-4.6,14.3-8.4c-12.9-1.9-24.4,8.4-32,19
		c-7.7,10.8-14.5,23.3-26.6,28.8c-3.6,1.7-7.4,2.5-11.3,3c3,2.8,6.1,5.4,9.7,7.4C922.2,760.3,929.7,761.8,936.2,759.3z"
                />
                <path
                  id="XMLID_80_"
                  fill="#5E7561"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1026.3,753.2c-8.8-15.7-18.7-30.7-31.4-43.3
		c-5.1-5-11-9.8-18-11.1c-0.2,0-0.3,0-0.5-0.1c-3.4,3.8-9.5,5.8-14.3,8.4c-6.6,3.6-11.7,9.7-14.2,16.7c-2.9,8.3-2.1,17.4-4,26
		c-0.4,1.9-1,3.9-2.2,5.5c-1.3,1.8-3.3,3-5.4,3.8c-6.5,2.5-14,1-20-2.3c-3.6-2-6.8-4.6-9.7-7.4c-10.9,1.2-22.6-1.4-33.9-0.6
		c-9.7,3.9-17.2,12.7-22.5,21.9c-6.4,11-10.8,23.1-18.4,33.3c-11.5,15.3-30.3,25.8-40.8,41.1c13.1,8,26.4,15.5,39.9,22.6
		c2.6-3.2,5.1-6.4,7.7-9.6c8.2-10.2,16.9-20.9,29-25.7c17.8-7.1,38.1,0.2,54.7,9.8c16.6,9.6,32.4,21.9,51.2,25.7
		c11.3,2.3,22.9,1.3,34.4,2.6c2.8,0.3,5.5,0.7,8.2,1.3c17.6-12.5,32.9-28.1,44.9-46c0.9-1.4,1.8-2.7,2.7-4.1
		C1049.6,800.2,1039,775.9,1026.3,753.2z"
                />
                <path
                  id="XMLID_56_"
                  fill="#839662"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M829.8,907.5c13.4-22.8,27.2-46.1,47.4-63.2
		c7-5.9,15.3-11.2,24.4-11.5c-11.3-3.6-23.1-4.7-34-0.3c-12.2,4.8-20.9,15.5-29,25.7c-2.6,3.2-5.1,6.4-7.7,9.6
		c-15.6,19.5-31.2,39-46.7,58.5c-6.5,8.1-14.1,16.7-22.7,22.4c5.3,2.4,10.7,4.8,16,7.3c5.9-3.7,12.1-6.4,19-6.6
		c3.2-0.1,6.3,0.5,9.3,1.3c2.5-6.3,6-12.4,9.4-18.3C819.9,924.2,824.9,915.8,829.8,907.5z"
                />
                <path
                  id="XMLID_59_"
                  fill="#596D3A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1016.1,871.9c-2.7-0.5-5.4-1-8.2-1.3
		c-11.4-1.3-23.1-0.3-34.4-2.6c-18.8-3.8-34.6-16.1-51.2-25.7c-6.5-3.8-13.5-7.1-20.7-9.5c-9.1,0.3-17.4,5.6-24.4,11.5
		c-20.2,17.1-34,40.4-47.4,63.2c-4.9,8.4-9.8,16.7-14.7,25.1c-3.4,5.9-6.9,11.9-9.4,18.3c10.5,3,20.3,10.3,31.3,12.1
		c14.7,2.4,29.9-5.1,44.5-1.9c5.2,1.1,10,3.6,14.5,6.5c8.2-5.2,15.7-11.5,22.3-18.7c13.2-14.3,23.4-32.4,41-40.6
		c22.6-10.4,49.1-0.8,71.2,10.6c8.2,4.2,16.4,8.7,24.5,13.4c8.6-7.1,15.9-16.3,23.2-25.5C1059.9,890.1,1039.5,876.6,1016.1,871.9z"
                />
                <path
                  id="XMLID_12_"
                  fill="#516289"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M701.7,938.2c-12.6-13.4-36.4-22-55.9-22.1
		c-4.8,2.4-9.4,5.3-13.9,8.2c-8.1,5.2-16.2,10.4-24.3,15.6c39.1,15.8,75.5,40.4,115.3,55.1c1,0.4,1.9,0.7,2.9,1
		c10.3-8.1,20.6-16.3,30.9-24.4c-14.7-2.8-29.3-6.8-40.2-16.8C711,949.9,706.8,943.6,701.7,938.2z"
                />
                <path
                  id="XMLID_16_"
                  fill="#919FBC"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M761.3,948.8c-24.5-11.1-49-22.3-73.5-33.4
		c-0.8-0.4-1.6-0.7-2.4-1.1c-3.8-1.7-7.7-3.3-11.7-4c-9.6-1.7-19.1,1.2-28,5.8c19.6,0.1,43.3,8.7,55.9,22.1
		c5.1,5.4,9.3,11.7,14.8,16.7c10.9,9.9,25.5,14,40.2,16.8c3.2-2.5,6.4-5.1,9.7-7.6c3.5-2.8,7.1-5.6,11-8
		C772,953.6,766.7,951.2,761.3,948.8z"
                />
                <path
                  id="XMLID_23_"
                  fill="#8492AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1066.6,748.3c4.7-11.7,16.6-18.7,24-28.8
		c5.9-8.1,8.7-18,13.8-26.6c3.6-6.2,9.1-11.9,15.5-14.4c-4.2-0.4-8.4-0.2-12.6,0.8c-16,3.9-27.1,18-37.8,30.6
		c-13.2,15.6-27.7,30.1-43.3,43.4c7.1,12.6,13.5,25.8,20.3,38.7c7.2-9.4,13.6-19.3,16.6-30.6C1064.3,756.9,1065,752.5,1066.6,748.3z
		"
                />
                <path
                  id="XMLID_82_"
                  fill="#566384"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1221.5,761.8c-4.2-0.9-8.8-0.9-12.7-2.8
		c-3.7-1.8-6.3-5.1-8.9-8.2c-8.9-11.1-17.7-22.2-26.6-33.4c-4.1-5.2-8.3-10.5-12.8-15.4c-4.6-5-9.5-9.7-15.1-13.6
		c-7.5-5.3-16.4-9.1-25.5-10c-6.5,2.5-11.9,8.3-15.5,14.4c-5.1,8.6-7.9,18.5-13.8,26.6c-7.4,10.2-19.3,17.2-24,28.8
		c-1.7,4.2-2.3,8.7-3.4,13c-3,11.3-9.4,21.3-16.6,30.6c5.3,10.2,10.9,20.3,17.1,29.8c11.7-17.7,24.5-38.1,45.2-38.4
		c21-0.3,34.9,20.4,48.4,36.5c10.2,12.2,22.3,22.8,35.7,31.5c12.1-15.1,22.8-31.8,33.2-47.9c5.3-8.1,10.9-16.5,18.1-22.7
		C1238.7,771.5,1231.9,764.1,1221.5,761.8z"
                />
                <path
                  id="XMLID_90_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1065.1,866.2c6.4-8,10.4-17.6,17.1-25.4
		c4-4.7,9-8.6,12-14c2.1-3.9,3.1-8.2,4.8-12.4c5-12.4,16.3-22.1,29.3-25.2c-5.8-3.8-12.1-6.1-19.3-6c-20.7,0.3-33.6,20.6-45.2,38.4
		c-0.9,1.4-1.8,2.8-2.7,4.1c-12,17.9-27.3,33.6-44.9,46c10.4,2.1,20.1,5.9,29.4,11C1052.9,878.3,1059.7,873,1065.1,866.2z"
                />
                <path
                  id="XMLID_93_"
                  fill="#516289"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1157.3,819.8c-8.9-10.6-17.9-23.2-29.1-30.5
		c-13,3.1-24.3,12.8-29.3,25.2c-1.6,4.1-2.6,8.5-4.8,12.4c-3,5.4-8,9.3-12,14c-6.7,7.7-10.7,17.4-17.1,25.4
		c-5.4,6.8-12.2,12.1-19.7,16.6c11.7,6.3,22.5,14.6,32.7,23.8c10-12.4,20.2-24.8,34.2-31.5c16.7-8.1,36.5-7,54.3-1.6
		c1.5,0.5,3,1,4.5,1.5c7.9-7.1,15.1-15.2,21.9-23.7C1179.6,842.6,1167.5,832,1157.3,819.8z"
                />
                <path
                  id="XMLID_10_"
                  fill="#5C647A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1335.1,593.8c-5.7-7.5-14.1-16-23.1-12.9
		c-7.6,2.6-10.2,12-15.8,17.7c-11.7,12-30.9,5.2-48.2,5.4c-7.3,5.4-14.2,15.2-14.8,22.4c-0.7,9.5,1.4,19,0,28.4
		c-2.8,18.9-18.4,32.8-30,47.9c-7.1,9.2-13,19.4-17.5,30.2c4.7,5.9,9.5,11.9,14.2,17.8c2.5,3.2,5.2,6.5,8.9,8.2
		c3.9,1.9,8.4,1.8,12.7,2.8c10.4,2.3,17.2,9.7,22.9,18.8c2.7-2.3,5.6-4.4,8.9-6c12.9-6.5,27.5-5.2,42.1-4.2
		c23.3-42.2,60.1-76.7,83.3-119.2C1364.2,632,1349.7,612.9,1335.1,593.8z"
                />
                <path
                  id="XMLID_13_"
                  fill="#6F7D9B"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1233.3,654.8c1.4-9.4-0.7-19,0-28.4
		c0.5-7.2,7.4-17,14.8-22.4c-3.1,0-6.2,0.3-9.1,0.9c-23.2,5-33.8,31.1-44.4,52.3C1186,674,1175,690,1160.5,702
		c4.5,4.9,8.7,10.2,12.8,15.4c4.1,5.2,8.2,10.3,12.4,15.5c4.5-10.8,10.4-21,17.5-30.2C1214.9,687.6,1230.5,673.7,1233.3,654.8z"
                />
                <path
                  id="XMLID_81_"
                  fill="#596D3A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1401.4,695.3c-3.3,18.7-15.9,34.2-24.6,51.1
		c13-3.7,26.7-4.7,40.5-4.6V638.2c-6.8,7.6-11.6,17-13.4,27.1C1402.2,675.2,1403.2,685.4,1401.4,695.3z"
                />
                <path
                  id="XMLID_85_"
                  fill="#839662"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1395.5,620.1c-5.5,7.3-9,15.8-13.1,24
		c-1.2,2.4-2.4,4.7-3.7,7c0.2,0.2,0.3,0.4,0.5,0.7c-0.2-0.2-0.3-0.4-0.5-0.7c-23.2,42.5-60,77.1-83.3,119.2c9.8,0.6,19.6,1.1,28.9-1
		c15.4-3.4,28.3-13.6,42.8-19.6c3.2-1.3,6.4-2.4,9.7-3.4c8.7-16.9,21.3-32.4,24.6-51.1c1.8-9.9,0.8-20.1,2.5-30
		c1.8-10,6.5-19.5,13.4-27.1v-35C1408.7,606.9,1401.1,612.6,1395.5,620.1z"
                />
                <path
                  id="XMLID_84_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1249,801.4c2.7-4.6,5.6-9.4,10-12.4
		c10.8-7.2,24.9-1.1,37.7,0.9c11.3,1.7,22.9-0.1,34.1-2.6c2.8-0.6,5.6-1.3,8.2-2.5c2.8-1.3,5.2-3.2,7.6-5
		c16.1-12.3,32.2-24.6,48.3-36.9c-9.6,1.1-18.9,3.2-27.9,6.9c-14.5,6-27.4,16.1-42.8,19.6c-9.3,2.1-19.1,1.6-28.9,1
		c-14.7-1-29.3-2.3-42.1,4.2c-3.2,1.6-6.2,3.7-8.9,6c-7.2,6.3-12.9,14.6-18.1,22.7c-10.4,16.1-21.1,32.8-33.2,47.9
		c-6.8,8.5-14,16.5-21.9,23.7c11.7,4,22.7,9.5,33.7,15.1c9.9-13.3,15.1-30.7,21.7-46.3C1232.8,829,1240.9,815.2,1249,801.4z"
                />
                <path
                  id="XMLID_88_"
                  fill="#5E7561"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1395,742.8c-16.1,12.3-32.2,24.6-48.3,36.9
		c-2.4,1.9-4.9,3.7-7.6,5c-2.6,1.2-5.4,1.9-8.2,2.5c-11.2,2.5-22.8,4.3-34.1,2.6c-12.8-1.9-26.9-8.1-37.7-0.9
		c-4.5,3-7.3,7.8-10,12.4c-8.1,13.8-16.2,27.6-22.4,42.4c-6.6,15.6-11.8,33.1-21.7,46.3c4.1,2.1,8.2,4.2,12.4,6.2
		c5.6,2.7,11.2,5.3,16.9,7.8c16.8-13,35.6-25.1,56-21.7c9.9,1.6,18.8,6.8,28.1,10.7c6.4,2.7,12.9,4.7,19.6,6.1
		c7.9-4.6,15.8-9.3,23.7-13.9c5-2.9,10-5.8,14.1-9.8c4.7-4.5,8.2-10.1,12-15.4c7.1-10,15.4-19.1,23.7-28.2c2-2.2,3.9-4.3,5.9-6.5
		v-83.7C1409.8,741.7,1402.3,742,1395,742.8z"
                />
                <path
                  id="XMLID_97_"
                  fill="#6F7D9B"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1337.8,899.2c-6.6-1.5-13.2-3.5-19.6-6.1
		c-6-2.5-11.8-5.5-17.8-7.8c-9.8,2-19.9,4.5-27.4,11c-6.3,5.5-10,13.4-15,20.1c-14.5,19.7-38.5,29.4-58.6,43.4
		c-17.2,11.9-31.8,27.3-42.9,45.1c3.3,0.5,6.5,1,9.7,1.5c7.8-5.9,15.3-12.1,22.7-18.5c8.4-7.3,16.9-15.1,27.4-18.5
		c12.6-4.1,26.2-1.3,39.2,1.4c53.9,11.5,107.8,22.9,161.7,34.4V898.1C1390.6,902.6,1363.4,904.9,1337.8,899.2z"
                />
                <path
                  id="XMLID_102_"
                  fill="#939EB2"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1199.4,959.8c20.1-13.9,44.2-23.6,58.6-43.4
		c5-6.8,8.7-14.6,15-20.1c7.5-6.6,17.6-9,27.4-11c-3.3-1.3-6.7-2.3-10.3-2.9c-20.4-3.4-39.2,8.7-56,21.7c-2.8,2.2-5.5,4.4-8.2,6.5
		c-29.1,23.4-59.7,44.9-91.6,64.3c-14.6,8.9-29.5,17.3-44.6,25.3c18.9,2.4,38.1,1.7,57.1,3.5c3.3,0.3,6.5,0.7,9.8,1.1
		C1167.6,987.2,1182.3,971.7,1199.4,959.8z"
                />
                <path
                  id="XMLID_87_"
                  fill="#919FBC"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1417.3,825.5c-2,2.2-4,4.3-5.9,6.5
		c-8.3,9.1-16.5,18.2-23.7,28.2c-3.8,5.3-7.3,10.9-12,15.4c-4.2,3.9-9.2,6.9-14.1,9.8c-7.9,4.6-15.8,9.3-23.7,13.9
		c18.1,4,37.1,4.1,56.1,2.1c9.2-11.3,17.1-23.6,23.4-36.7V825.5z"
                />
                <path
                  id="XMLID_91_"
                  fill="#616F87"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1417.3,864.7c-6.4,13.1-14.2,25.4-23.4,36.7
		c7.8-0.8,15.6-1.9,23.4-3.2V864.7z"
                />
                <path
                  id="XMLID_92_"
                  fill="#8492AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1085.2,940.7c5.6-4.9,12-9,16.6-14.9
		c4.4-5.7,6.8-12.7,9.7-19.4c4.8-10.8,11.5-21,21-28.1c5.6-4.2,12.4-7.2,19.3-8.3c-13.4-1.9-27.3-0.9-39.4,5
		c-14,6.8-24.2,19.1-34.2,31.5c-7.4,9.2-14.6,18.4-23.2,25.5c8.2,4.7,16.4,9.5,24.6,14.2C1081.3,944.4,1083.2,942.5,1085.2,940.7z"
                />
                <path
                  id="XMLID_96_"
                  fill="#596A91"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1217.2,896.3c-15.2-7.5-30.1-15.9-46.1-21.4
		c-1.5-0.5-3-1-4.5-1.5c-4.8-1.5-9.9-2.6-14.9-3.3c-6.9,1.1-13.6,4-19.3,8.3c-9.5,7.1-16.2,17.3-21,28.1c-2.9,6.6-5.3,13.6-9.7,19.4
		c-4.6,5.9-11,9.9-16.6,14.9c-2,1.8-3.8,3.7-5.6,5.6c17.9,10.4,36,20.4,54.7,28.5c31.9-19.4,62.5-40.9,91.6-64.3
		c2.7-2.2,5.4-4.3,8.2-6.5C1228.4,901.6,1222.8,899,1217.2,896.3z"
                />
                <path
                  id="XMLID_58_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M941.6,956.6c7.4-11.4,12.4-24.4,20.8-35.1
		c6.9-8.9,17.3-16.2,28.2-17c-10.7-1.6-21.3-0.9-31.2,3.7c-17.6,8.2-27.9,26.3-41,40.6c-6.6,7.1-14.1,13.4-22.3,18.7
		c3.7,2.4,7.2,5,10.8,7.6c3.5,2.5,7.1,4.9,10.7,7.2C926.9,975.2,935.1,966.5,941.6,956.6z"
                />
                <path
                  id="XMLID_62_"
                  fill="#516289"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1054.9,932.2c-8.1-4.6-16.2-9.1-24.5-13.4
		c-12.4-6.4-26.2-12.2-39.9-14.3c-10.9,0.8-21.2,8.1-28.2,17c-8.4,10.7-13.4,23.6-20.8,35.1c-6.4,9.9-14.6,18.6-24.1,25.7
		c4.7,3,9.4,5.8,14.3,8.4c11.2-7.2,23.3-12.7,36.6-14.9c38.6-6.4,75.5,16.6,114.1,23.4c2.4,0.4,4.9,0.8,7.3,1.1
		c15.1-8,30-16.4,44.6-25.3C1107,963.1,1081.2,947.1,1054.9,932.2z"
                />
                <path
                  id="XMLID_54_"
                  fill="#616B84"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M906.7,975.1c-3.5-2.5-7.1-5.2-10.8-7.6
		c-4.5-2.9-9.3-5.3-14.5-6.5c-14.6-3.2-29.8,4.3-44.5,1.9c-6.5-1.1-12.5-4-18.6-6.9c-11.1-1.2-22.8,3-30.4,11.1
		c-9.7,10.4-12.4,25.6-20.8,37c-6.2,8.5-15,14.4-24.3,19.4c36.9,16.1,76.3,26.2,116.4,29.8c7.5-6.2,14.8-12.7,21.9-19.3
		c16.1-15.1,32.3-31.7,50.7-43.5C923.1,986,914.7,980.8,906.7,975.1z"
                />
                <path
                  id="XMLID_57_"
                  fill="#8B94A5"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M767.1,1004.2c8.4-11.5,11.2-26.6,20.8-37
		c7.6-8.2,19.3-12.4,30.4-11.1c-7.1-3.4-14.3-6.7-22-6.5c-6.8,0.1-13.1,2.9-19,6.6c-3.8,2.4-7.5,5.2-11,8
		c-13.5,10.7-27,21.3-40.6,32c-5.2,4.1-10.4,8.2-15.6,12.3c9.1,4.3,18.1,8.7,27,12.8c1.8,0.8,3.7,1.7,5.6,2.5
		C752.1,1018.5,760.9,1012.6,767.1,1004.2z"
                />
                <path
                  id="XMLID_6_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M712,1136.1c5.5-5,11.7-9,17.6-13.5
		c12.2-9.4,22.7-21.1,35.4-29.9c10.8-7.5,29.5-13.1,43.9-11.9c-5.7-3.7-11.7-6.9-18.3-8.8c-31.4-9-62,14-87.4,34.4
		c-15.6,12.5-31.5,24.7-47.6,36.5c14,3.7,27.9,7.5,41.6,11.8C701.4,1147.8,706.1,1141.4,712,1136.1z"
                />
                <path
                  id="XMLID_11_"
                  fill="#566384"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1081.8,1220c-57-47.7-133.7-63.1-201-94.6
		c-18.6-8.7-36.5-18.6-53-30.8c-3.9-2.9-7.8-5.9-11.8-8.8c-2.4-1.7-4.7-3.4-7.2-5c-14.5-1.2-33.1,4.4-43.9,11.9
		c-12.7,8.8-23.1,20.5-35.4,29.9c-5.9,4.5-12.1,8.5-17.6,13.5c-5.9,5.4-10.6,11.7-14.8,18.5c20.7,6.4,41,13.9,60.1,24.1
		c25.4,13.6,48.5,31.7,75.3,42.3c27.7,11,58.7,13.9,83.9,29.8c19.9,12.6,34,31.9,49.2,50.1c44.2-21.9,88.3-43.9,132.5-65.8
		C1093,1229.9,1087.5,1224.8,1081.8,1220z"
                />
                <path
                  id="XMLID_100_"
                  fill="#8B94A5"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M937.1,1118.6c14.7-9.5,30.8-17.6,42.2-30.9
		c8.5-9.8,13.9-22,22.4-31.7c6.8-7.8,19.3-14.9,30.2-15.2c-9.7-3.1-19.8-4.2-30.1-2.4c-23.6,4.2-41.6,22.7-59.3,39
		c-19.2,17.7-39.9,33.8-61.8,48c10.6,4.9,21.4,9.5,32.3,13.8C919.6,1131.1,928.3,1124.3,937.1,1118.6z"
                />
                <path
                  id="XMLID_101_"
                  fill="#616B84"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1080.3,1072.6c-3.4-3.4-7-6.6-10.7-9.8
		c-11.3-9.5-24.1-17.7-37.7-22c-10.9,0.3-23.4,7.4-30.2,15.2c-8.5,9.7-13.9,21.9-22.4,31.7c-11.4,13.3-27.5,21.4-42.2,30.9
		c-8.8,5.7-17.5,12.5-24,20.7c58.6,23.3,120.7,40.5,168.7,80.8c5.7,4.8,11.2,9.9,16.4,15.2c12-5.9,23.9-11.9,35.9-17.8
		c15.6-7.7,31.6-15.6,47.9-22.2C1146.1,1157.6,1117.8,1109.6,1080.3,1072.6z"
                />
                <path
                  id="XMLID_8_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M889.8,1085.7c4.6-4.8,7.5-10.9,10.6-16.8
		c7.1-13.7,15.1-26.8,24-39.4c7-9.9,14.8-19.8,25.4-25.6c7.2-4,15.4-5.9,22.9-9.5c8.8-4.2,20.2-13.3,31-17.6
		c-11.7-2.3-23.4-3.1-35.3-1.2c-13.3,2.2-25.4,7.6-36.6,14.9c-18.4,11.8-34.6,28.4-50.7,43.5c-7.1,6.6-14.4,13.1-21.9,19.3
		c-13.8,11.5-28.2,22.3-43.1,32.4c4,2.9,7.8,6,11.8,8.8c7.8,5.7,15.9,11,24.2,15.8c7.9-4.8,15.9-9.6,23.8-14.4
		C880.8,1093,885.8,1090,889.8,1085.7z"
                />
                <path
                  id="XMLID_9_"
                  fill="#597A59"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1146.8,1003.7c-19-1.8-38.2-1.1-57.1-3.5
		c-2.4-0.3-4.9-0.7-7.3-1.1c-26.7-4.7-52.5-17.2-78.7-22.3c-10.8,4.3-22.2,13.4-31,17.6c-7.5,3.6-15.7,5.5-22.9,9.5
		c-10.6,5.8-18.4,15.7-25.4,25.6c-8.9,12.6-16.9,25.8-24,39.4c-3.1,5.9-6,11.9-10.6,16.8c-4,4.2-9.1,7.3-14,10.3
		c-7.9,4.8-15.9,9.6-23.8,14.4c9.3,5.4,19,10.4,28.8,15c21.9-14.3,42.6-30.3,61.8-48c17.6-16.3,35.7-34.8,59.3-39
		c24.5-4.3,48.2,8,67.7,24.4c34.4-14.8,66.9-33.7,96.7-56.4C1159.8,1005.3,1153.3,1004.4,1146.8,1003.7z"
                />
                <path
                  id="XMLID_95_"
                  fill="#516289"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1259.2,971.6c-9-0.9-18.6-1.5-26.6,2.7
		c-12.8,6.8-16.8,22.8-24.4,35.1c-6.9,11.1-17.1,19.5-27.2,27.8c-14.9,12.2-29.9,24.4-46.6,33.8c-12.1,6.8-25.4,12.3-36.7,20
		c29.4,33.6,54.2,72.6,84.5,104.2c30.2-12.2,61.5-20.4,93.5-16.3c16.1,2,31.6,7.1,47.4,11.1c30.8,7.7,62.7,10.8,94.4,9.5v-194.1
		C1364.6,994,1311.9,982.8,1259.2,971.6z"
                />
                <path
                  id="XMLID_99_"
                  fill="#919FBC"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1180.9,1037.2c10.1-8.2,20.4-16.7,27.2-27.8
		c7.6-12.3,11.6-28.4,24.4-35.1c8-4.2,17.6-3.6,26.6-2.7c-1.2-0.3-2.4-0.5-3.6-0.8c-12.9-2.8-26.6-5.5-39.2-1.4
		c-10.6,3.4-19.1,11.2-27.4,18.5c-7.4,6.4-14.9,12.6-22.7,18.5c-29.8,22.7-62.3,41.6-96.7,56.4c3.7,3.1,7.3,6.4,10.7,9.8
		c5.9,5.9,11.7,12,17.2,18.4c11.4-7.7,24.6-13.2,36.7-20C1151,1061.6,1166,1049.4,1180.9,1037.2z"
                />
                <path
                  id="XMLID_65_"
                  fill="#596D3A"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M858.4,1285.2c-25.2-24.4-58.2-40.5-93-45.3
		c-39-5.4-82.4,2.3-114.8-20.1c-12.4-8.6-21.8-20.8-32.5-31.4c-37.1-36.5-89.1-52.2-137.7-71c-11.8-4.6-23.6-9.4-34.4-16
		c-30.2-18.4-51.4-49.2-81.8-67.2c-32.7-19.3-71.7-21.6-110.2-20.2c-14.3,3.1-28.4,6.9-42.2,11.4c-48.7,15.7-94.7,39.8-135.2,71.2
		C51,1116.5,27,1139.5,0,1157v260.3h608.2c40.4-23.1,82.3-43.6,128-50.6c29.2-4.5,59-3.3,88.1-8.3c28.8-5,56-15.9,82.6-28.4
		C888.4,1319.7,874.1,1300.4,858.4,1285.2z"
                />
                <path
                  id="XMLID_70_"
                  fill="#839662"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M965.7,1301c-15.2-18.2-29.3-37.5-49.2-50.1
		c-25.2-15.9-56.3-18.8-83.9-29.8c-26.8-10.7-49.9-28.8-75.3-42.3c-31.8-17-66.7-26.5-101.7-35.9c-37.3-10-74.8-19.7-108.7-37.8
		c-19.8-10.5-38.2-23.8-56.4-37.2c-33.4-24.6-66.4-49.6-106.3-59.1c-21.2-5-43.3-5.3-65.1-3.9c-21.8,1.4-43.6,4.5-64.9,9.2
		c38.5-1.4,77.5,0.9,110.2,20.2c30.4,18,51.6,48.8,81.8,67.2c10.8,6.6,22.6,11.4,34.4,16c48.5,18.8,100.6,34.5,137.7,71
		c10.8,10.6,20.1,22.8,32.5,31.4c32.4,22.4,75.8,14.7,114.8,20.1c34.7,4.8,67.8,20.9,93,45.3c15.7,15.2,30,34.5,48.4,44.7
		c9.9-4.6,19.6-9.5,29.3-14.3C946,1310.8,955.9,1305.9,965.7,1301z"
                />
                <path
                  id="XMLID_98_"
                  fill="#7389AF"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M956.3,1382.7c32.3-10.2,62.2-27.9,86.8-51.3
		c10.7-10.2,20.3-21.4,31.4-31.1c16.9-14.7,36.7-25.7,57.2-34.7c26.6-11.7,55.1-20.7,77.6-39.2c11.1-9.1,20.3-20.3,31-29.9
		c8.1-7.2,17-13.4,26.5-18.5c-28.9-1.7-57.3,6-84.7,17.2c-16.3,6.6-32.3,14.4-47.9,22.2c-12,5.9-23.9,11.9-35.9,17.8
		c-44.2,21.9-88.3,43.9-132.5,65.8c-9.9,4.9-19.7,9.8-29.6,14.7c-35.9,17.8-72.4,35.9-111.9,42.7c-29.1,5-58.9,3.8-88.1,8.3
		c-45.7,7-87.6,27.5-128,50.6h259.2c9.7-7.2,20.3-13.2,31.5-17.7C917.4,1392.2,937.2,1388.7,956.3,1382.7z"
                />
                <path
                  id="XMLID_103_"
                  fill="#596A91"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M1417.3,1199.4c-31.7,1.3-63.6-1.8-94.4-9.5
		c-15.7-3.9-31.3-9-47.4-11.1c-2.9-0.4-5.9-0.6-8.8-0.8c-9.5,5.1-18.5,11.3-26.5,18.5c-10.7,9.5-19.9,20.7-31,29.9
		c-22.4,18.5-50.9,27.5-77.6,39.2c-20.5,9-40.4,19.9-57.2,34.7c-11.1,9.7-20.7,20.9-31.4,31.1c-24.5,23.4-54.4,41.1-86.8,51.3
		c-19,6-38.9,9.5-57.4,17c-11.2,4.5-21.8,10.5-31.5,17.7h550V1199.4z"
                />
                <path
                  id="XMLID_69_"
                  fill="#7D967F"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M737.2,1021.1c-8.9-4.1-17.9-8.5-27-12.8
		c-29.1-13.6-59.2-25.4-89.2-16.9c-0.5,0.1-1,0.3-1.4,0.4c18.9,6.6,35.7,23.4,55.6,29.6c10.4,3.3,21.5,3.6,32.2,6
		c11.9,2.7,23,8,33.9,13.3c26.9,12.9,53.9,26,78.8,42.2c13.5-9.3,26.5-19.2,39-29.6C817.1,1049.6,775.6,1038.7,737.2,1021.1z"
                />
                <path
                  id="XMLID_73_"
                  fill="#5E7561"
                  stroke="#000000"
                  strokeMiterlimit="10"
                  d="M707.4,1027.5c-10.6-2.4-21.8-2.7-32.2-6
		c-19.9-6.3-36.7-23.1-55.6-29.6c-24,7.4-42.3,26.5-62.4,41.9c0.4,0.2,0.8,0.4,1.2,0.6c-0.4-0.2-0.8-0.4-1.2-0.6
		c-0.4,0.3-0.8,0.6-1.2,0.9c-19.7,14.8-42,26.1-65.6,33.2c18.3,13.4,36.7,26.7,56.4,37.2c33.9,18.1,71.4,27.8,108.7,37.8
		c16.2-11.8,32.1-23.9,47.6-36.5c25.4-20.5,56-43.4,87.4-34.4c9.4,2.7,17.6,8,25.5,13.8c1.4-0.9,2.7-1.8,4.1-2.8
		c-24.9-16.3-52-29.3-78.8-42.2C730.3,1035.5,719.2,1030.2,707.4,1027.5z"
                />
              </g>

              <rect id="border" rx="20px" ry="20px" />
            </svg>
          </div>
          <h1 className="text-4xl sm:text-5xl text-gray-300 font-black glow-text">
            Happy Pongal
          </h1>
          <h1 className="text-6xl sm:text-8xl text-gray-300 font-black glow-text">
            2025
          </h1>
          <p className="text-gray-200 max-w-lg mx-auto">
            கடுமையான உழைப்பே மக்களை வறுமையில் இருந்து மீட்கும். சமதர்ம சமுதாயம்
            மலர வன்முறை தேவை இல்லை. கல்வியும் உழைப்பும் போதுமானது. — காமராஜ்
          </p>

          <p className="sm:text-lg">
            Warm wishes from the{" "}
            <span className="text-lg sm:text-xl font-black bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500 text-transparent bg-clip-text">
              Keture
            </span>{" "}
            team.
          </p>
        </div>
      </section>
    </Fragment>
  );
}
