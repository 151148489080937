import React, { useEffect, Fragment } from "react";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Timeline, Tweet } from "react-twitter-widgets";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import team from "../assets/team.png";
// import teem from "../assets/teem.jpg";
import c001 from "../assets/c001.png";
import c002 from "../assets/c002.png";
import c003 from "../assets/c003.png";
import c004 from "../assets/c004.png";
import c005 from "../assets/c005.png";
import c006 from "../assets/c006.png";
import c007 from "../assets/c007.png";

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    var words = document.getElementsByClassName("word");
    var wordArray = [];
    var currentWord = 0;

    words[currentWord].style.opacity = 1;
    for (var i = 0; i < words.length; i++) {
      splitLetters(words[i]);
    }

    function changeWord() {
      var cw = wordArray[currentWord];
      var nw =
        currentWord === words.length - 1
          ? wordArray[0]
          : wordArray[currentWord + 1];
      for (var i = 0; i < cw.length; i++) {
        animateLetterOut(cw, i);
      }

      for (i = 0; i < nw.length; i++) {
        nw[i].className = "letter behind";
        nw[0].parentElement.style.opacity = 1;
        animateLetterIn(nw, i);
      }

      currentWord = currentWord === wordArray.length - 1 ? 0 : currentWord + 1;
    }

    function animateLetterOut(cw, i) {
      setTimeout(function () {
        cw[i].className = "letter out";
      }, i * 45);
    }

    function animateLetterIn(nw, i) {
      setTimeout(function () {
        nw[i].className = "letter in";
      }, 340 + i * 45);
    }

    function splitLetters(word) {
      var content = word.innerHTML;
      word.innerHTML = "";
      var letters = [];
      for (var i = 0; i < content.length; i++) {
        var letter = document.createElement("span");
        letter.className = "letter";
        letter.innerHTML = content.charAt(i);
        word.appendChild(letter);
        letters.push(letter);
      }

      wordArray.push(letters);
    }

    changeWord();
    setInterval(changeWord, 3500);
  });
  const products = [
    {
      id: 1,
      name: "Mobile App Development",
      icon: "M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM144 448c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H160c-8.8 0-16 7.2-16 16zM304 64H80V384H304V64z",
      desc: "Creating innovative and user-friendly mobile apps for iOS, Android, and cross-platform using Flutter, a modern and versatile mobile app development framework.",
      delay: 0,
      color: "#00ff00",
    },
    {
      id: 2,
      name: "Web App Development",
      icon: "M0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm64 32v64c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zM80 320c-13.3 0-24 10.7-24 24s10.7 24 24 24h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H80zm136 0c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H216z",
      desc: "Developing scalable and high-performance web apps using JavaScript frameworks like React, or Vue.js for seamless user experiences on the web.",
      delay: 0,
      color: "#0073ff",
    },
    {
      id: 3,
      name: "Research and Development",
      icon: "M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z",
      desc: "Driving innovation through ongoing R&D initiatives, exploring new technologies, and methodologies.",
      delay: 0,
      color: "#ff00e1",
    },
    {
      id: 4,
      name: "IT Consulting",
      icon: "M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z",
      desc: "Providing strategic IT consulting services for optimizing clients' IT infrastructure and achieving their business goals.",
      delay: 0,
      color: "#fff200",
    },
    {
      id: 5,
      name: "Custom Software Development",
      icon: "M162.4 6c-1.5-3.6-5-6-8.9-6h-19c-3.9 0-7.5 2.4-8.9 6L104.9 57.7c-3.2 8-14.6 8-17.8 0L66.4 6c-1.5-3.6-5-6-8.9-6H48C21.5 0 0 21.5 0 48V224v22.4V256H9.6 374.4 384v-9.6V224 48c0-26.5-21.5-48-48-48H230.5c-3.9 0-7.5 2.4-8.9 6L200.9 57.7c-3.2 8-14.6 8-17.8 0L162.4 6zM0 288v32c0 35.3 28.7 64 64 64h64v64c0 35.3 28.7 64 64 64s64-28.7 64-64V384h64c35.3 0 64-28.7 64-64V288H0zM192 432a16 16 0 1 1 0 32 16 16 0 1 1 0-32z",
      desc: "Designing and implementing bespoke software solutions tailored to clients' unique needs.",
      delay: 0,
      color: "#b700ff",
    },
    {
      id: 6,
      name: "Quality Assurance and Testing",
      icon: "M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z",
      desc: "Ensuring software quality and reliability through comprehensive testing and QA processes.",
      delay: 0,
      color: "#00ffff",
    },
    {
      id: 7,
      name: "Agile Development Methodologies",
      icon: "M246.6 150.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0l96 96c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L352 109.3V384c0 35.3 28.7 64 64 64h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H416c-70.7 0-128-57.3-128-128c0-35.3-28.7-64-64-64H109.3l41.4 41.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-96-96c-12.5-12.5-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L109.3 256H224c23.3 0 45.2 6.2 64 17.1V109.3l-41.4 41.4z",
      desc: "Delivering software solutions faster and efficiently using Agile methodologies like Scrum or Kanban.",
      delay: 0,
      color: "#80ff00",
    },
    {
      id: 8,
      name: "UX/UI Design",
      icon: "M64 416L168.6 180.7c15.3-34.4 40.3-63.5 72-83.7l146.9-94c3-1.9 6.5-2.9 10-2.9C407.7 0 416 8.3 416 18.6v1.6c0 2.6-.5 5.1-1.4 7.5L354.8 176.9c-1.9 4.7-2.8 9.7-2.8 14.7c0 5.5 1.2 11 3.4 16.1L448 416H240.9l11.8-35.4 40.4-13.5c6.5-2.2 10.9-8.3 10.9-15.2s-4.4-13-10.9-15.2l-40.4-13.5-13.5-40.4C237 276.4 230.9 272 224 272s-13 4.4-15.2 10.9l-13.5 40.4-40.4 13.5C148.4 339 144 345.1 144 352s4.4 13 10.9 15.2l40.4 13.5L207.1 416H64zM279.6 141.5c-1.1-3.3-4.1-5.5-7.6-5.5s-6.5 2.2-7.6 5.5l-6.7 20.2-20.2 6.7c-3.3 1.1-5.5 4.1-5.5 7.6s2.2 6.5 5.5 7.6l20.2 6.7 6.7 20.2c1.1 3.3 4.1 5.5 7.6 5.5s6.5-2.2 7.6-5.5l6.7-20.2 20.2-6.7c3.3-1.1 5.5-4.1 5.5-7.6s-2.2-6.5-5.5-7.6l-20.2-6.7-6.7-20.2zM32 448H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32z",
      desc: "Creating intuitive and visually appealing user interfaces (UI) and user experiences (UX) for software applications.",
      delay: 0,
      color: "#ffa600",
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Keture</title>
        <meta name="title" content="Keture"></meta>
        <meta
          name="description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture" />
        <meta
          property="og:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture" />
        <meta
          property="twitter:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div className="texto max-w-8xl mx-auto">
        <div className="banner bg-gradient-to-br from-yellow-400 to-red-600 grid content-center">
          <div className="bg-ke">
            <b>Ke.</b>
          </div>

          <h1 className="ke font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-yellow-50 to-red-50">
            Keture
          </h1>
          <h1>
            <span className="container">
              <span className="word">powers IT communities</span>
              <span className="word">provides delightful services</span>
              <span className="word">produces #1 apps</span>
            </span>
          </h1>

          <div className="text-center md:max-w-2xl lg:max-w-4xl mx-auto z-10">
            <p className="text-md md:text-xl px-5">
              <b className="font-extrabold">Keture</b> is a dynamic, fully
              remote software service provider. Though small,{" "}
              <b className="font-extrabold">Keture</b> aims to make a meaningful
              impact by focusing on agility and innovation. Always adapting to
              the latest trends and adhering to best industry practices,{" "}
              <b className="font-extrabold">Keture</b> is committed to
              delivering secure, scalable, and cost-effective solutions to meet
              modern challenges.
            </p>
          </div>

          <a
            href="/events"
            rel="noopener noreferrer"
            className="flex items-start my-4 font-extralight mx-auto"
          >
            <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link constant-tilt-shake text-4xl font-extrabold flex">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-10" viewBox="0 0 24 24">
                <title>white-balance-sunny</title>
                <path
                  fill="currentColor"
                  d="M3.55 19.09L4.96 20.5L6.76 18.71L5.34 17.29M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12C18 8.68 15.31 6 12 6M20 13H23V11H20M17.24 18.71L19.04 20.5L20.45 19.09L18.66 17.29M20.45 5L19.04 3.6L17.24 5.39L18.66 6.81M13 1H11V4H13M6.76 5.39L4.96 3.6L3.55 5L5.34 6.81L6.76 5.39M1 13H4V11H1M13 20H11V23H13"
                />
              </svg>
              {/* <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="tree"
                className="w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5.8,16.59L4.5,15.28L12.26,7.5L16.5,11.74L8.72,19.5L7.29,18.09C7.04,18.16 6.8,18.28 6.63,18.5C6.57,18.57 6.5,18.65 6.5,18.74C6.42,18.88 6.38,19 6.32,19.15C6.21,19.42 6.09,19.69 5.93,19.93C5.81,20.1 5.68,20.26 5.53,20.39C5.42,20.5 5.29,20.59 5.16,20.66C5.08,20.71 5,20.76 4.9,20.79C4.3,21.04 3.63,21 3,21V19C3.23,19 3.83,19 3.9,19C4,19 4.08,19 4.16,18.94C4.18,18.92 4.19,18.91 4.21,18.89C4.28,18.81 4.34,18.7 4.39,18.6C4.47,18.42 4.53,18.24 4.6,18.06L4.64,17.96C4.76,17.69 4.9,17.45 5.08,17.23C5.18,17.1 5.3,17 5.42,16.87C5.54,16.77 5.66,16.67 5.8,16.59M21,3L19.88,11.19L12.81,4.12L21,3Z"
                />
              </svg> */}
            </div>
          </a>
        </div>
        {/* <img src={teem} alt="teem" className="teem-img" /> */}
        <div className="bg-gradient-to-b from-gray-900 via-gray-800  to-gray-800 z-20  ">
          <div className="bg-img">
            <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
              <h2
                className="text-3xl md:text-5xl font-extrabold tracking-tight text-gray-300 mb-12"
                data-aos="fade-in"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                Our Expertise
              </h2>
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-8 items-center">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="group rounded-md bg-white/5 py-2 px-5 ring-1 ring-white/10 opacity-90 isolate overflow-hidden relative h-full"
                    data-aos="fade-in"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000"
                  >
                    <div className="aspect-w-1 aspect-h-1 overflow-hidden opacity-50 group-hover:opacity-70 justify-center flex mt-5">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter"
                        className="w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path fill={product.color} d={product.icon}></path>
                      </svg>
                    </div>
                    <div className="mt-4 flex justify-between">
                      <div>
                        <h3 className="text-md text-gray-300 font-medium">
                          <a href={product.href}>
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            {product.name}
                          </a>
                        </h3>
                        <p className="mt-1 text-base font-extralight text-gray-300">
                          {product.desc}
                        </p>
                      </div>
                    </div>
                    <svg
                      className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
                      viewBox="0 0 1155 678"
                      fill="none"
                    >
                      <path
                        fill={`url(#${product.id})`}
                        fillOpacity=".5"
                        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                      />
                      <defs>
                        <linearGradient
                          id={product.id}
                          x1="1155.49"
                          x2="-78.208"
                          y1=".177"
                          y2="474.645"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor={product.color} />
                          <stop offset={1} stopColor={product.color} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                ))}
              </div>
              <div
                className="mx-auto space-y-2 bg-gradient-to-r from-blue-900 to-sky-500 pt-10 mt-10 rounded-lg overflow-x-clip"
                data-aos="fade-in"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                <div className="max-w-6xl mx-auto text-center px-5 relative z-10">
                  <h4 className="text-2xl text-gray-200 font-black my-5 md:text-4xl">
                    Are you a software development team looking for specialized
                    sessions or expert assistance?
                  </h4>

                  <Link to="/services">
                    <button className="bg-gray-100 hover:bg-gray-300 text-sky-800 font-bold py-3 px-5 rounded text-sm md:text-lg">
                      Explore our comprehensive range of services ⟶
                    </button>
                  </Link>
                </div>
                <img src={team} alt="team" className="team-img" />
              </div>
              <div
                className=" bg-slate-200 rounded-lg p-12 mt-10 mx-auto text-center"
                data-aos="fade-in"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                <h2 className="text-3xl font-bold tracking-tight text-slate-700">
                  Clients We've Worked With
                </h2>
                <div className="lg:max-w-5xl mx-auto text-center mb-12">
                  <p className=" text-slate-600 ">
                    Meet the clients we've had the pleasure of working with. As
                    a software service company, we're thankful for the chance to
                    create tailored solutions that bring their ideas to life.
                    Our collaboration turns visions into practical software,
                    contributing to the success of each project.
                  </p>
                </div>

                <div
                  x-data="{}"
                  x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
                  className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_80px,_black_calc(100%-80px),transparent_100%)]"
                >
                  <ul
                    x-ref="logos"
                    className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
                  >
                    <li>
                      <img
                        src={c001}
                        alt="RG"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c002}
                        alt="OP"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c003}
                        alt="DH"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c004}
                        alt="F3"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c005}
                        alt="DP"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c006}
                        alt="TC"
                        className="c-logo object-contain"
                      />
                    </li>
                    <li>
                      <img
                        src={c007}
                        alt="PL"
                        className="c-logo object-contain"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/* <iframe
                title="google-reviews"
                src="https://b9533b3997ea4be1af94a79a8d0ce144.elf.site"
                width="100%"
                height="600"
                frameBorder="0"
                className="mt-6 g-rwe"
              ></iframe> */}
            </div>
            <div className="flex justify-center pb-10">
              <div
                className="flex max-w-7xl justify-center self-center items-center flex-wrap"
                data-aos="fade-in"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                <div className="text-2xl sm:text-6xl text-left font-black mr-5 leading-snug text-gray-300 p-10 max-w-3xl self-center">
                  Our Twitter is like a dev diary. Our RTs are not endorsements.
                  We keep tweeting what we like to share with y'all.
                </div>
                <div className=" min-w-max p-10 overflow-y-hidden rounded-">
                  <Tweet tweetId="1499061134921449477" />
                  <Timeline
                    dataSource={{
                      sourceType: "profile",
                      screenName: "Keturecom",
                    }}
                    options={{
                      height: "500",
                      width: "300",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
