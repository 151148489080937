import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../assets/logo-sml.png";
import { Link, useLocation } from "react-router-dom";
const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "Services", href: "/services", current: false },
  { name: "Projects", href: "/projects", current: false },
  { name: "Team", href: "/team", current: false },
  { name: "About", href: "/about", current: false },
  {
    name: "emptyseat.lk",
    href: "https://emptyseat.lk/",
    current: false,
    special: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const location = useLocation(); // Get the current location object

  // Determine which tab is selected based on the pathname
  const isActive = (pathname) => {
    return location.pathname === pathname ? "active" : "";
  };
  return (
    <Disclosure
      as="nav"
      className="bg-gray-800 sticky top-0 z-50 px-2 opacity-95 isolate overflow-hidden"
    >
      {({ open }) => (
        <>
          <div className="max-w-9xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
                <Link className="flex-shrink-0 flex items-center" to={"/"}>
                  <img
                    className="block lg:hidden h-10 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-10 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <h1 className="px-2 text-white text-4xl font-bold">Keture</h1>
                </Link>
                <div className="hidden md:block sm:ml-6 absolute right-0 items-center">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          isActive(item.href)
                            ? "rounded-md bg-white/5 ring-1 ring-white/10 opacity-90"
                            : item.special
                            ? "bg-gradient-to-r from-[#ff9000] to-[#ff0030] text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-md font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : item.special
                      ? "bg-gradient-to-r from-[#ff9000] to-[#ff0030] text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
          <svg
            className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
            viewBox="0 0 1155 678"
            fill="none"
          >
            <path
              fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#f97316" />
                <stop offset={1} stopColor="#fbbf24" />
              </linearGradient>
            </defs>
          </svg>
        </>
      )}
    </Disclosure>
  );
}
